module.exports = {
	"selectors": {
		"date": "Date",
		"card_brand": "Card brand",
		"card_type": "Card type",
		"card_country": "Card country",
		"customer_type": "Customer type",
		"widget_mode": "Widget mode",
		"last_status_change": "By last status change date",
		"processor": "Processor",
		"payment_system": "PS",
		"payment_type": "Payment type",
		"type": "Type",
		"status": "Status",
		"country": "Country",
		"customer_country": "Customer country",
		"token_country": "Country by IP",
		"customer_or_token_country": "Country (customer's or IP)",
		"currency": "Currency",
		"customer_currency": "Customer currency",
		"processor_currency": "Processor currency",
		"fee_currency": "Fee currency",
		"order_currency": "Order currency",
		"error_type": "Failure type",
		"error_subtype": "Failure subtype",
		"purse": "Purse",
		"user": "User",
		"no_user": "No user",
		"old_status": "Old status",
		"new_status": "New status",
		"bank": "Bank",
		"no_bank": "No bank",
		"platform": "Platform",
		"name_or_url": "Name or URL",
		"refcodes": "Refcodes",
		"log_type": "Type",
		"log_action": "Action",
		"elements_per_page": "Elements per page",
		"billing_currency": "Billing currency",
		"integration_status": "Status",
		"system_payment_type": "Payment type",
		"continent": "Continent",
		"period": "Period",
		"sort_type": "Sorting",
		"fee_type": "Fee",
		"alert_type": "Type",
		"processor_channel": "Processor channel",
		"no_processor_channel": "No processor channel",
		"billing_types": "Billing types",
		"event_log_types": "Type",
		"others": "Others",
		"supervising_manager": "Supervising manager",
		"callback_status": "Status",
		"title": "Title",
		"ftd": "FTD",
		"three_ds_status": "3DS status",
		"purse_country": "Purse country",
		"no_purse_country": "No purse country",
		"amount": "Amount",
		"failure_code": "Failure code",
		"failure_message": "Failure message",
		"no_failure_type": "No failure type",
		"show_unused": "Show unused",
		"customer_types": {
			"real": "Real",
			"test": "Test",
			"test_partner": "Test partner"
		},
		"dates": {
			"from": "From",
			"to": "to",
			"whole_period": "Whole period",
			"last_month": "Last month",
			"current_month": "Current month",
			"thirty_days": "30 days",
			"seven_days": "7 days",
			"week": "Week",
			"yesterday": "Yesterday",
			"today": "Today"
		},
		"placeholder": {
			"default": "Nothing selected",
			"purse": "Enter purse number",
			"comma_separated": "Enter comma separated values",
			"name_or_url": "Enter processor name or URL"
		},
		"helpers": {
			"select_all": "Select all",
			"clear": "Clear",
			"search": "Search"
		},
		"country_areas": {
			"ru": "Russian Federation",
			"cis": "CIS",
			"euro_union": "Europe (EU)",
			"not_euro_union": "Europe (Non EU)",
			"west_asia": "West Asia",
			"east_asia": "East Asia",
			"africa": "Africa",
			"australia_and_oceania": "Australia and Oceania",
			"south_america": "South America",
			"north_america": "North America",
			"others": "Others"
		},
		"order_columns_title": "Columns",
		"order_columns": {
			"amount": "Amount",
			"bank_id": "Bank",
			"billing_amount": "Billing amount",
			"billing_currency": "Billing currency",
			"billing_type": "Billing type",
			"refundable_amount": "Refundable amount",
			"created_at": "Created at",
			"customer": "Customer",
			"customer_country": "Customer country",
			"customer_fee": "Customer fee",
			"deposit_number": "Deposit number",
			"failure_code": "Failure code",
			"failure_type": "Failure type",
			"id": "ID",
			"jurisdiction": "Jurisdiction",
			"locale": "Locale",
			"order_currency": "Order currency",
			"personal_fee": "Personal fee",
			"platform": "Platform",
			"processor_account_title": "Processor account",
			"processed_at": "Processed at",
			"processor_channel_id": "Processor channel",
			"processor_fee": "Processor fee (calculated)",
			"processor_fee_external": "Processor fee (external)",
			"processor_name": "Processor",
			"project_fee": "Project fee",
			"project_name": "Project",
			"purse": "Purse",
			"refcode": "Refcode",
			"rejection_reason": "Rejection reason",
			"scheduled_at": "Scheduled at",
			"secondary_txids": "Secondary transaction IDs",
			"status": "Status",
			"status_updated_at": "Status updated at",
			"sphere_link": "Sphere link",
			"support_status": "Support status",
			"system_name": "System",
			"three_ds_status": "3DS status",
			"token_country": "Country by IP",
			"txid": "Transaction ID",
			"type": "Type",
			"updated_at": "Updated at",
			"user": "User",
			"purse_country": "Purse country",
			"card_type": "Card type"
		},
		"deposit_default_amount_strategy": {
			"last_deposit": "Last deposit",
			"average": "Average",
			"rule_or_rating": "Rule or rating",
			"max_of_last_deposit_and_average": "Max of last deposit and average"
		},
		"widget_screen": {
			"deposit": "Deposit",
			"withdraw": "Withdraw",
			"deposit-form": "Deposit form",
			"withdraw-form": "Withdraw form",
			"clarification-form": "Clarification form",
			"frame": "PS frame",
			"form": "PS form",
			"confirmation": "Confirmation",
			"instruction": "Instruction",
			"message": "Message"
		},
		"vwo": {
			"name": "VWO Variation",
			"not_participating": "<Not participating>"
		},
		"card_types": {
			"no_data": "No data"
		},
		"timerange": {
			"time": "Time",
			"start": "Start time",
			"end": "End time"
		}
	}
};